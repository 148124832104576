<template>
    <div class="main">
        <div class="title">发布供给信息-脱密专利</div>
        <div class="top">
            <el-form
                :model="formData"
                :rules="rules"
                ref="form"
                label-width="122px"
                label-position="right"
                size="small"
                inline
            >
                <el-form-item prop="name" label="专利名称">
                    <el-input v-model="formData.name" placeholder="请输入专利名称"></el-input>
                </el-form-item>
                <el-form-item prop="applyTime" label="申请日期" style="margin-left: 122px">
                    <el-date-picker
                        style="width: 200px"
                        v-model="formData.applyTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="code" label="专利申请号">
                    <el-input v-model="formData.code" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item prop="ipc" label="IPC分类号" style="margin-left: 122px">
                    <el-input v-model="formData.ipc" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item prop="industryClass" label="行业分类">
                    <el-select v-model="formData.industryClass" placeholder="请选择" style="width: 200px">
                        <el-option
                            v-for="item in industryOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="lawStatus" label="法律状态" style="margin-left: 122px">
                    <el-select
                        v-model="formData.lawStatus"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 200px"
                    >
                        <el-option
                            v-for="item in lawStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="owner" label="专利权人" class="block">
                    <el-select
                        style="width: 400px"
                        v-model="formData.owner"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入专利权人，按回车分割"
                        clearable
                        popper-class="hideSelect"
                    >
                    </el-select>
                </el-form-item>

                <el-form-item prop="inventor" label="发明人" class="block" style="margin-right: 142px">
                    <el-select
                        style="width: 400px"
                        v-model="formData.inventor"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入发明人，按回车分割"
                        clearable
                        popper-class="hideSelect"
                    >
                        <div slot="empty"></div>
                    </el-select>
                </el-form-item>
                <el-form-item prop="img" label="专利附图" class="block" style="margin-right: 240px">
                    <multi-upload v-model="formData.img"></multi-upload>
                </el-form-item>
                <el-form-item prop="digest" label="专利摘要" class="block">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        v-model="formData.digest"
                        maxlength="300"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 6 }"
                        placeholder="请填写专利摘要描述，300字以内"
                        resize="none"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="manual" label="专利说明" class="block">
                    <rich-text v-model="formData.manual"></rich-text>
                </el-form-item>

                <el-form-item prop="tradingMethod" label="交易方式" class="block">
                    <el-radio-group v-model="formData.tradingMethod">
                        <el-radio v-for="item in tradingMethodOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :min="0"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="width: 200px"
                    >
                    </el-input-number>

                    <el-checkbox style="margin-left: 10px" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>

                <el-form-item prop="contact" label="联系人" style="margin-left: 58px">
                    <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
                </el-form-item>

                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        style="width: 200px"
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        placeholder="请输入"
                        :controls="false"
                    >
                    </el-input-number>
                </el-form-item>

                <el-form-item prop="phone" label="联系电话" style="margin-left: 120px">
                    <el-input v-model="formData.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>

                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="email" label="邮箱" style="margin-left: 164px">
                    <el-input v-model="formData.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>

                <el-form-item
                    style="margin-right: 112px"
                    prop="commission"
                    :label="`佣金${formData.commissionType == 'COMMISSION' ? '（万元）' : '（%）'}`"
                >
                    <el-input v-model="formData.commission" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item prop="address" label="所在地区" class="block">
                    <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="onSave">保存</el-button>
                <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import MultiUpload from '../../components/MultiUpload.vue';
import RichText from '../../components/RichText.vue';
import {
    patentTypeOptions,
    lawStatusOptions,
    ownerTypeOptions,
    tradingMethodOptions,
    commissionTypeOptions,
    phonePattern
} from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    components: { RichText, MultiUpload },
    data() {
        return {
            saving: false,
            formData: {
                inventor: [],
                owner: [],
                commissionType: 'COMMISSION'
            },
            rules: {
                name: [{ required: true, message: '请输入专利名称' }],
                code: [{ required: true, message: '请输入专利申请号' }],
                patentType: [{ required: true, message: '请选择专利类型' }],
                industryClass: [{ required: true, message: '请选择行业分类' }],
                lawStatus: [{ required: true, message: '请选择法律状态' }],
                ownerType: [{ required: true, message: '请输入专利权人' }],
                owner: [{ required: true, message: '请输入专利权人' }],
                inventor: [{ required: true, message: '请输入发明人' }],
                img: [{ required: true, message: '请上传专利附图' }],
                digest: [{ required: true, message: '请输入专利摘要' }],
                manual: [{ required: true, message: '请输入专利说明' }],
                tradingMethod: [{ required: true, message: '请选择交易方式' }],
                expectedPrice: [{ required: true, message: '请输入期望价', trigger: 'blur' }],
                contact: [{ required: true, message: '请输入联系人' }],
                basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                commissionType: [{ required: true, message: '请选择佣金类型' }],
                email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                address: [{ required: true, message: '请输入所在地区' }]
            },
            industryOptions: [],
            patentTypeOptions,
            lawStatusOptions,
            ownerTypeOptions,
            tradingMethodOptions,
            commissionTypeOptions,
            inputValue: ''
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.industryOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = {
                ...this.formData,
                userId: this.userInfo.id,
                caseType: 'DECLASSIFICATION',
                inventor: [],
                owner: [],
                tradingMethod: 'TRANSFER',
                commissionType: 'COMMISSION',
                contact: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            data.owner = data.owner.join(',');
            this.saving = true;
            console.log(data);
            this.$http
                .post('/patent/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-button {
    width: 110px;
    margin-left: 25px;
}
/deep/ .tox-editor-container {
    width: 800px;
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-top: 30px;
    }
}
</style>
